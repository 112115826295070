
import { formatPageToPost } from '@/utils/pages';
import BlogPostCard from './BlogPostList/BlogPostCard';

export default {
  name: 'BlogPostsList1',
  components: {
    BlogPostCard,
  },
  props: {
    blogPostList: {
      type: Object,
      required: false,
    },
    blogList: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      default: () => ({ page: 0, limit: 0, count: 0 }),
    },
    authorName: { type: String },
    hasPagination: {
      type: Boolean,
      default: false,
    },
    hasSearch: {
      type: Boolean,
    },
  },
  computed: {
    hasPosts() {
      return this.posts.length > 0;
    },
    visiblePagination() {
      return this.hasPagination && this.pagination.count > this.pagination.limit;
    },
    posts() {
      return _.orderBy(
        this.blogList.map((page) => formatPageToPost(page)),
        'createdDate',
        'desc'
      );
    },
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(page) {
        const baseUrl = this.$route.params.pathMatch;
        this.$router.push({ path: `/${baseUrl}`, query: { page: page > 1 ? page : undefined } });
      },
    },
  },
  data() {
    return {
      query: this.$route.query.query,
    };
  },
  methods: {
    onSearch: _.debounce(function () {
      this.startSearch();
    }, 1000),
    startSearch() {
      const query = JSON.parse(JSON.stringify(this.$route.query));
      if (query.query === this.query) return;
      query.query = this.query;
      if (!query.query) delete query.query;
      this.$router.push({ query });
    },
  },
};
