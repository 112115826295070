import { render, staticRenderFns } from "./BlogPostCard.vue?vue&type=template&id=21e4910c"
import script from "./BlogPostCard.vue?vue&type=script&lang=js"
export * from "./BlogPostCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Skeleton: require('/codebuild/output/src3752812432/src/client/components/skeleton/index.vue').default})
